// scss-lint:disable QualifyingElement, DuplicateProperty

// Reboot Adjustments

//
// HTML (element)
//
html {
  @include media-breakpoint-down(xs) {
    font-size: 15px;
  }
}

//
// Body
//

body {
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $body-color;
  background-color: $body-bg;
}


//
// Links
//

a {
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

b, strong {
  font-weight: $font-weight-bold;
}

//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}
