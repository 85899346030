//
// Base class .list-group inherited from Bootstrap
//

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive
// list items. Includes an extra `.active` modifier class for selected items.

.list-group-item-action {
  color: $list-group-action-color;
  @include transition($list-group-transition);

  // Hover state
  @include hover-focus {
    color: $list-group-action-hover-color;
    background-color: $list-group-hover-bg;
  }

  // Active state
  &:active {
    color: $list-group-action-active-color;
    background-color: $list-group-action-active-bg;
  }
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  padding: $list-group-item-padding-y $list-group-item-padding-x;
  margin-bottom: -$list-group-border-width;
  background-color: $list-group-bg;
  border: $list-group-border-width solid $list-group-border-color;

  &:first-child {
    @include border-top-radius($list-group-border-radius);
  }

  &:last-child {
    @include border-bottom-radius($list-group-border-radius);
  }

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color;
    background-color: $list-group-disabled-bg;
  }

  // Include both here for `<a>`s and `<button>`s
  &.active {
    color: $list-group-active-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border-color;
  }
}

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $color, $value in $theme-colors {
  @include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}
