//
// Alerts adjustments
//

// Base styles.
.alert {
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: none;
  @include border-radius(0);
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}

// Dismissible alerts
.alert-dismissible {
  .close {
    top: -$alert-padding-y;
    right: -$alert-padding-x;
    padding: $alert-padding-y $alert-padding-x;
    @include transition($transition-base);

    &:hover {
      cursor: pointer;
    }
  }
}

// Override alert theme colors without mixin.
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    color: lighten($value, 48%);
    background-color: $value;

    .alert-link {
      color: lighten($value, 48%);
    }

    // Exception for alerts with a light background.
    @if $color == "light" {
      @include color-yiq($value);

      .alert-link {
        @include color-yiq($value);
      }
    }
  }
}
