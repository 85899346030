// Input Groups Adjustments

//
// Text input groups
//

// Allow seamless input group integration using utility classes.
.input-group {
    &[class*="with-addon-icon"] {
      position: relative;

      .input-group-addon {
        position: absolute;
        background: none;
        border: none;
        z-index: 100;
      }

      .form-control {
        border-radius: $input-border-radius;
      }
    }

    &.with-addon-icon-left {
      .form-control {
        padding-left: 40px;
      }

      .input-group-addon {
        left: 0;
      }
    }

    &.with-addon-icon-right {
      .form-control {
        padding-right: 40px;
      }

      .input-group-addon {
        right: 0;
      }
    }
  }

.input-group-addon {
  padding: $input-group-padding-y $input-group-padding-x;
  font-weight: $font-weight-normal;
  line-height: $input-btn-line-height;
  color: $input-group-color;
  background-color: $input-group-addon-bg;
  border: $input-btn-border-width solid $input-group-addon-border-color;
  @include border-radius($input-border-radius);

  // Sizing
  &.form-control-sm {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    font-size: $font-size-sm;
    @include border-radius($input-border-radius-sm);
  }

  &.form-control-lg {
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
    font-size: $font-size-lg;
    @include border-radius($input-border-radius-lg);
  }

  &.with-icon {
    background: $white;
  }

  // Fix material icons display inside input groups.
  i.material-icons {
    top: 0;
  }
}

//
// Button input groups
//

.input-group-btn {

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn + .btn {
      margin-left: (-$input-btn-border-width);
  }

  // Negative margin to only have a single, shared border between the two
  &:not(:last-child) {
    > .btn,
    > .btn-group {
      margin-right: (-$input-btn-border-width);
    }
  }

  &:not(:first-child) {
    > .btn,
    > .btn-group {
      margin-left: (-$input-btn-border-width);
    }
  }
}
